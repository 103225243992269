@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #dbf3ff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  font-size: 64px;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: 10% 60%;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 900px;
}

.splash {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  clip-path: path(
    "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
  );
}

/* Glitch */

.stack {
  display: grid;
  grid-template-columns: 1fr;
}

.stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) { --glitch-translate: 8px; }
.stack span:nth-child(even) { --glitch-translate: -8px; }

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 #AB0E20, 2px -3px 0 #02AED9;
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 #AB0E20, -2px 3px 0 #02AED9;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 #AB0E20, 2px -3px 0 #02AED9;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 #AB0E20, -2px 3px 0 #02AED9;
  }
  4%, 100% {  text-shadow: none; transform: none; }
}

/* floatingContainer */

.floatingContainer {
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.1);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
		transform: translatey(0px);
	}
}

.floatingArrow {
	animation: floatArrow 3s ease-in-out infinite;
}

@keyframes floatArrow {
  0%   { transform: translate(0, -20px); opacity: 0; }
  50%  { opacity: 1; }
  100% { transform: translate(0, 20px); opacity: 0; }
}

/* Max font */

.fontMax {
  font-size: 90px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .fontMax {
    font-size: 50px;
  }
}

/* Card Animated */

.cardAnimated {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardAnimated:nth-child(odd) {
  align-self: flex-end;
}

/* Magenet nav.magnetButtonWrapper Button */

@layer foundation {
	body::before {
    /* --line: hsl(0 0% 20% / 0.2); */
    content: "";
    height: 100vh;
    width: 100vw;
    position: fixed;
    background:
      linear-gradient(90deg, var(--line) 1px, transparent 1px 10vmin) 0 -5vmin / 10vmin 10vmin,
      linear-gradient(var(--line) 1px, transparent 1px 10vmin) 0 -5vmin / 10vmin 10vmin;
    mask: linear-gradient(-15deg, transparent 60%, white);
    top: 0;
    z-index: -1;
    transform: translate3d(0, 0, -100vmin);
  }

	nav.magnetButtonWrapper ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	nav.magnetButtonWrapper li {
		border-radius: 1.275rem;
		display: grid;
		place-items: center;
	}
	nav.magnetButtonWrapper a {
		text-decoration: none;
		color: hsl(0 0% 10%);
		outline-offset: 0.5rem;
		border-radius: 1.275rem;
	}
	nav.magnetButtonWrapper a span {
		display: inline-block;
	}

	.controls {
		position: fixed;
		top: 1rem;
		right: 1rem;
		display: flex;
		gap: 0.5rem;
		color: hsl(0 0% 50%);
		display: grid;
		grid-template-columns: auto 1fr;
	}
}

@layer trickery {
	:root {
		--elastic-out: linear(
	    0, 0.2178 2.1%, 1.1144 8.49%,
	    1.2959 10.7%, 1.3463 11.81%,
	    1.3705 12.94%, 1.3726, 1.3643 14.48%,
	    1.3151 16.2%, 1.0317 21.81%,
	    0.941 24.01%, 0.8912 25.91%,
	    0.8694 27.84%, 0.8698 29.21%,
	    0.8824 30.71%, 1.0122 38.33%, 1.0357,
	    1.046 42.71%, 1.0416 45.7%,
	    0.9961 53.26%, 0.9839 57.54%,
	    0.9853 60.71%, 1.0012 68.14%,
	    1.0056 72.24%, 0.9981 86.66%, 1
	  );
	}
	nav.magnetButtonWrapper {
		--padding-x: calc(var(--item-inline, 1) * 1rem);
		--padding-y: calc(var(--item-block, 0.5) * 1rem);
	}
	/* Implementation for following tab */
	ul:is(:hover, :focus-within) { --follow: 1; }
	:root:has(#follow:checked) ul::after {
		content: "";
		background: hsl(0 0% 95%);
		border-radius: 1.275rem;
		position: fixed;
		opacity: var(--follow, 0);
		top: calc((var(--at) * 1px) + (var(--item-block) * 1rem));
		left: calc((var(--al) * 1px) + (var(--item-inline) * 1rem));
		height: calc((var(--ah) * 1px) - (var(--item-block) * 2rem));
		width: calc((var(--aw) * 1px) - (var(--item-inline) * 2rem));
		z-index: -1;
		pointer-events: none;
		translate:
			calc(clamp(-1, var(--list-x), 1) * var(--padding-x, 1rem))
			calc(clamp(-1, var(--list-y), 1) * var(--padding-y, 1rem));
/*		transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s;*/
		transition:
			top 0.25s,
			left 0.25s,
			width 0.25s,
			height 0.25s,
			opacity 0.25s,
			translate var(--speed, 0.25s) var(--ease, ease-out);
	}
	:root:has(#follow:checked) a {
		background-color: transparent;
	}
	ul:has(li:hover a) {
		--ease: ease;
		--speed: 0.1s;
	}
	ul:has(li a:is(:hover, :focus-within)) {
		--speed: 0s;
	}
	/* End follow implementation	*/
	nav.magnetButtonWrapper li {
		padding: var(--padding-y) var(--padding-x);
		outline: 2px dashed transparent;
		transition: outline-color 0.5s;
	}
	:root:has(#outline:checked) nav.magnetButtonWrapper li {
		outline-color: var(--theme);
	}
	nav.magnetButtonWrapper li:hover {
		--active: 1;
	}
	nav.magnetButtonWrapper li:hover a {
		--ease: ease;
		--speed: 0.1s;
	}
	nav.magnetButtonWrapper li a:is(:hover, :focus-within) {
		--active: 1;
		--speed: 0s;
	}
	nav.magnetButtonWrapper a {
		--accent: hsl(0 0% calc((100 - (var(--active, 0) * 5)) * 1%));
		background-color: var(--accent);
		/* background-color: #fc6601; */
		padding: 1rem;
		outline-color: var(--accent);
	}
	nav.magnetButtonWrapper a span {
		--padding-x: calc(1rem * var(--text-offset, 0.25));
		--padding-y: calc(1rem * var(--text-offset, 0.25));
	}
	:where(nav.magnetButtonWrapper a, nav.magnetButtonWrapper a span) {
		translate:
			calc(clamp(-1, var(--magnet-x), 1) * var(--padding-x, 1rem))
			calc(clamp(-1, var(--magnet-y), 1) * var(--padding-y, 1rem));
		transition: translate var(--speed, 0.25s) var(--ease, ease-out), outline-color 0.25s, background-color 0.25s;
	}
}

.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: #02AED9;
  transform-origin: 0%;
  z-index: 900000;
}

/* Timeline */

.message {
	position: absolute;
	top: 0;
	left: 0;
	height: 300px;
	width: 50vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 20px;
}

.message h2 {
	line-height: 1;
}

.graph {

}

.graph img {
	width: auto;
	max-height: 16rem;
}

.timelineContent {

}

.even .timelineContent {
	text-align: right;
}

.odd .timelineContent {
	text-align: left;
}

.even .timelineContentImageWrapper {
	justify-content: end;
}

.odd .timelineContentImageWrapper {
	justify-content: flex-start;
}

.vertical-timeline::before {
	height: calc(100% - 80px) !important;
	top: 40px !important; 
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
	border-radius: 20px;
}

.odd .vertical-timeline-element-content {
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,246,255,1) 100%);
}

.even .vertical-timeline-element-content {
	background: linear-gradient(0deg, rgba(0,165,231,1) 0%, rgba(2,174,217,1) 100%);
	color: #fff;
}

.vertical-timeline-element-content h1 {
	font-family: 'Oswald';
}

.even .vertical-timeline-element-content h1 {
	color: #fff;
}

.even .vertical-timeline-element-content-arrow {
	border-right-color: #02aed9;
}

@media only screen and (max-width: 1169px) {
	.even .timelineContentImageWrapper {
		justify-content: flex-start;
	}

	.even .timelineContent {
		text-align: left;
	}
}

.parallaxOnVisible {
	opacity: 0;  
	transition: opacity 0.8s;
}
  
.parallaxOnVisible.visible {
	opacity: 1;
}

.css-selector {
    background: linear-gradient(71deg, #14242d, #143c52, #232323);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 90%}
    50%{background-position:100% 11%}
    100%{background-position:0% 90%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 90%}
    50%{background-position:100% 11%}
    100%{background-position:0% 90%}
}
@keyframes AnimationName {
    0%{background-position:0% 90%}
    50%{background-position:100% 11%}
    100%{background-position:0% 90%}
}

/* custom scrollbar */

::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}

/* blockquote */

.blockquote-wrapper {
	display: flex;
	padding: 0 20px;
	flex-direction: column;
 }
 
 /* Blockquote main style */
 .blockquote {
	 position: relative;
	 font-family: 'Barlow Condensed', sans-serif;
	 max-width: 620px;
	 margin: 20px auto;
	 align-self: center;
 }
 
 /* Blockquote header */
 .blockquote h1 {
	 font-family: 'Roboto';
	 position: relative; /* for pseudos */
	 color: #02AED9;
	 font-size: 1.2rem;
	 font-weight: 300;
	 line-height: 1.2;
	 text-align: left;
	 margin: 0;
	 border: 2px solid #fff;
	 border: solid 2px;
	 border-radius:40px;
	 padding: 25px;
 }

 .blockquote:hover {
	cursor: pointer;
 }

 .blockquote:hover h1 {
	color: #f08d65;
 }

 .blockquote:hover h1:after {
	border-color: #f08d65;
 }

 .blockquote:hover span {
	color: #ccc;
 }
 
 /* Blockquote right double quotes */
 .blockquote h1:after {
	 content:"";
	 position: absolute;
	 border: 2px solid #02AED9;
	 border-radius: 0 50px 0 0;
	 width: 60px;
	 height: 60px;
	 bottom: -60px;
	 left: 50px;
	 border-bottom: none;
	 border-left: none;
	 z-index: 3; 
 }
 
 .blockquote h1:before {
	 content:"";
	 position: absolute;
	 width: 80px;
	 border: 6px solid #14242d;
	 bottom: -3px;
	 left: 50px;
	 z-index: 2;
 }

 .blockquote.right h1:before {
	left: inherit;
	right: 50px;
 }

.blockquote.right h1:after {
	left: inherit;
	right: 50px;
	rotate: 270deg;
 }

 /* increase header size after 600px */
 @media all and (min-width: 600px) {
	 .blockquote h1 {
		 font-size: 1.6rem;
		 line-height: 1.2;
	}
 
 }
 
 /* Blockquote subheader */

 .quoteTitle {
	display: flex;
    flex-direction: column;
	padding-top: 15px;
	margin-left:130px;
	padding-left:12px;
	align-items: flex-start;
 }

 .blockquote.right .quoteTitle {
    margin-left: 0;
    margin-right: 130px;
    align-items: end;
 }
 
 .quoteTitle span {
	font-size: 11px;
	color: #a8bbbf;
 }

 .blockquote h4 {
	display: flex;
	position: relative;
	color: #ffffff;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.2;
	margin: 0;
	z-index: 1;
 }
  
 .blockquote h4:first-letter {
   margin-left:-12px;
 }

/* custom progress */
	
progress::-moz-progress-bar { background: #02AED9; }
progress::-webkit-progress-value { background: #02AED9; }
progress { color: #02AED9; }

.progressWrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}